export const filters = {
  price: {
    name: "Price",
    list: [],
  },
  width: {
    name: "Face Shape",
    list: [
      {
        label: "Round",
        value: "round",
        filterValues: ["medium", "narrow"],
      },
      {
        label: "Oval",
        value: "oval",
        filterValues: ["medium", "wide"],
      },
      {
        label: "Square",
        value: "square",
        filterValues: ["narrow", "medium"],
      },
      {
        label: "Diamond",
        value: "diamond",
        filterValues: ["medium", "narrow"],
      },
      {
        label: "Heart",
        value: "heart",
        filterValues: ["wide", "narrow"],
      },
    ],
  },
  shape: {
    name: "Frame Shape",
    list: [
      {
        label: "Round",
        value: "round",
      },
      {
        label: "Square",
        value: "square",
      },
      {
        label: "Rectangular",
        value: "rectangle",
      },
      {
        label: "Cat Eye",
        value: "cateye",
      },
    ],
  },
  material: {
    name: "Material",
    list: [],
  },
  color: {
    name: "Color",
    list: [
      { label: "Black", value: "black" },
      { label: "Blue", value: "blue" },
      { label: "Crystal", value: "crystal" },
      { label: "Gray", value: "gray" },
      { label: "Gold", value: "gold" },
      { label: "Multi-color", value: "multi-color" },
      { label: "Pink", value: "pink" },
      { label: "Red", value: "red" },
      { label: "Tortoise", value: "tortoise" },
      { label: "Green", value: "green" },
    ],
  },
  brand: {
    name: "Brand",
    list: [
      { label: "Hubble", value: "Hubble" },
      { label: "Acuvue 1-Day Moist", value: "Acuvue 1-Day Moist" },
      { label: "Acuvue Oasys", value: "Acuvue Oasys" },
      { label: "Acuvue Vita", value: "Acuvue Vita" },
      { label: "Bausch + Lomb Ultra", value: "Bausch + Lomb Ultra" },
      { label: "Biotrue", value: "Biotrue" },
    ],
  },
  lenseType: {
    name: "Lens Type",
    list: [
      { label: "Contacts for Astigmatism", value: "astigmatism" },
      { label: "Daily Disposable", value: "daily" },
      { label: "Weekly Disposable", value: "weekly" },
      { label: "Monthly Disposable", value: "monthly" },
    ],
  },
}
