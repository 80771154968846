import React, { useState, useEffect } from 'react'
import axios from "axios"


const ShareASalePixel = ({orderNumber, orderType, price, email}) => {
  const [clickID,setClickID] = useState("")
  const [newCustomer,setNewCustomer] = useState(1)




  useEffect(() => {
    if (typeof window !== 'undefined') {
      shareASaleGetCookie('shareasaleSSCID');
    }


    axios.get(`${process.env.GATSBY_ADMIN_URL}/api/user_info`, {
      params: {
        email: email,
        token: "11e842cac17bf63433384ed49e182911"
      },
    }).then((response) => {
      if (response?.data?.success === 200) {
        setNewCustomer(0)
      }
    })
    .catch((error) => {})


    .catch((error) => {})
  },[])


  const shareASaleGetCookie = (cookie) => {
    var r=cookie+"=";
    var a=decodeURIComponent(document.cookie);
    var o=a.split(";");

    for (var n=0;n<o.length;n++) {
      var t=o[n];

      while(t.charAt(0)==" ") {
        t=t.substring(1)
      }

      if (t.indexOf(r)==0) {
        setClickID(t.substring(r.length,t.length))
      }
    }

    return ""
  }


  return <div>
    {orderNumber !== undefined && orderType === "contacts" &&
      <img style={{"width": "1px", "height": "1px"}} src={`https://www.shareasale.com/sale.cfm?amount=${price}&tracking=${orderNumber}&merchantID=78935&transtype=sale&sscid=;${clickID}&sscidmode=6&storeID=1&newcustomer=${newCustomer}`} id="_SHRSL_img_1" alt=""/>
    }
    {/* {orderNumber !== undefined && (orderType === "frames" || orderType === "Frames" ) &&
      <img style={{"width": "1px", "height": "1px"}} src={`https://www.shareasale.com/sale.cfm?amount=${price}&tracking=${orderNumber}&merchantID=78935&transtype=sale&sscid=;${clickID}&sscidmode=6&storeID=1&skulist=${sku}&pricelist=${price}&quantitylist=1`} id="_SHRSL_img_1" alt=""/>
    } */}
  </div>

}

export default ShareASalePixel
