import React, { useState, useEffect, useContext } from "react"
import { navigate } from "gatsby"

import "../styles/pages/success-order.scss"
import CheckmarkIcon from "../images/assets/Radio/Checkmark.svg"
import NewButton from "../components/NewButton"
import CartContext from "../context/cartContext"
import { useWindowWidth } from "../services/hooks"
import ShareASalePixel from '../components/ShareASaleTrack'
import WebpImage from "../components/WebpImage"


const SuccessOrder = ({ location: { state} }) => {
  const { clearCart } = useContext(CartContext)
  const blueDots = "Backgrounds/blue-dots.png"
  const blueDotsMobile = "Backgrounds/blue-dots-mobile.png"
  const [isMobile, setIsMobile] = useState()
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 578)
}, [windowWidth])

// todo: research dependencies list
  useEffect(() => {
    if (state?.isSuccess) return clearCart()
    navigate('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!state?.isSuccess) return null

  return (
    <div className="success-order-container">
      <div className="grid-section">
        <div className="success-order">
          <div className="checked-image">
            <CheckmarkIcon />
          </div>
          <h3 className="heading text h3">Order Placed!</h3>
          <p className="description text h7 neue granite">
            You will receive an order confirmation email with details of your
            order and a link to track its progress.
          </p>
          <NewButton label="Back to Homepage" to="/" color="black" />
        </div>
      </div>
      <div className="background-dots">
        <WebpImage fileName={isMobile ? blueDotsMobile : blueDots} />
      </div>
      {state?.orderNumber &&
        <ShareASalePixel orderNumber={state?.orderNumber} orderType={state?.orderType} price={state?.price} email={state?.email} />
      }
    </div>
  )
}

export default SuccessOrder
