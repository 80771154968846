import { useState, useEffect, useMemo, useRef } from "react"
import { filters } from "../components/FilterTypes/mockedData"

export const useWindowWidth = () => {
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window?.innerWidth : 0
  )

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth)
    }

    window.addEventListener("resize", updateDimensions)

    updateDimensions()

    return () => window.removeEventListener("resize", updateDimensions)
  }, [])

  return width
}

export const useWindowScroll = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  const updateScroll = () => setIsScrolled(window.scrollY !== 0)

  useEffect(() => {
    window.addEventListener("scroll", updateScroll)

    updateScroll()

    return () => window.removeEventListener("scroll", updateScroll)
  }, [])

  return isScrolled
}

export const useHorizontalScroll = () => {
  const containerRef = useRef()

  useEffect(() => {
    const container = containerRef.current

    const onWheel = e => {
      if (!e.deltaY) return

      e.preventDefault()
      container.scrollTo({
        left: container.scrollLeft + e.deltaY,
        behavior: "smooth",
      })
    }

    container.addEventListener("wheel", onWheel)

    return () => container.removeEventListener("wheel", onWheel)
  }, [])

  return containerRef
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => clearTimeout(handler)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return debouncedValue
}

export const useArticles = (articles, filter) => {
  const { sort, tag } = filter

  return useMemo(() => {
    let sortedArticles = [...articles]

    if (tag) {
      sortedArticles = sortedArticles.filter(({ tags }) => tags?.includes(tag))
    }

    if (sort) {
      const [value, option] = sort.split("-")

      sortedArticles = sortedArticles.sort((a, b) => {
        if (option === "H" ? a[value] < b[value] : a[value] > b[value]) return 1
        if (option === "H" ? a[value] > b[value] : a[value] < b[value])
          return -1
        return 0
      })
    }

    return sortedArticles
  }, [sort, tag, articles])
}

export const useGlasses = (glasses, filter) => {
  const { price, width, shape, material, color, gender } = filter

  return useMemo(() => {
    let sortedGlasses = [...glasses]

    if (width.length) {
      const filterValues = filters.width.list
        .filter(filter => width.some(widthValue => filter.value === widthValue))
        ?.map(el => el.filterValues)
        ?.flat()
        ?.filter((currentFilter, index, allFilters) => {
          return allFilters.indexOf(currentFilter) === index
        })

      sortedGlasses = sortedGlasses.filter(node =>
        filterValues.some(e => node.size.toLowerCase().includes(e))
      )
    }

    if (shape.length) {
      sortedGlasses = sortedGlasses.filter(node =>
        shape.includes(node.shape.toLowerCase())
      )
    }

    if (color.length) {
      sortedGlasses = sortedGlasses.filter(({ hubbleFrameVariants }) =>
        hubbleFrameVariants.some(({ colorFilter }) =>
          color.some(e => colorFilter.toLowerCase().includes(e))
        )
      )
    }

    if (gender.length) {
      sortedGlasses = sortedGlasses.filter(({ productGender }) => {
        const nodeGender = productGender.toLowerCase()

        return nodeGender === "unisex" || gender.includes(nodeGender)
      })
    }

    return sortedGlasses
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [price, width, shape, material, color])
}
